var AttributesPage = function() {
    var _deleteUrl = '';


    var dragAndDropPosition = function () {
        $('.cloned-inputs').sortable({
            connectWith: ".attribute",
            items: ".attribute",
            opacity: 0.8,

            update: function(b, c) {
                reupdatePosition();
            }
        });
    };

    var reupdatePosition = function () {
        var positionInputs = $(document).find('input[name^="attributes_position"]');
        var i = 1;
        positionInputs.each(function(){
            $(this).val(i);
            i++;
        });
    };

    var initRemove = function () {
        $(document).find('.clone-remove').confirmation({
            container: 'body',
            btnOkClass: 'btn btn-sm btn-success',
            btnCancelClass: 'btn btn-sm btn-danger',
            singleton: true,
            onConfirm: function(){
                var div = $(this).closest('.clone-buttons');
                if (div.hasClass('new-clone')) {
                    // Make time for confirmation box to close
                    setTimeout(function() {
                        div.remove();
                    }, 500);
                } else {
                    var attrId = div.find('input').last().val();
                    $.ajax({
                        url: _deleteUrl,
                        method: "delete",
                        data: {
                            id: attrId
                        },
                        success: function(response) {
                            if (response.success) {
                                div.remove();
                            } else {
                                Notification.toastr().error('Attribute can\'t be deleted');
                            }
                        }
                    });
                }
            }
        });
    };

    /**
     * Copy active language value to other languages
     */
    var copyInput = function() {
        $('#copy-attribute-value').on('click', function () {
            $(document).find('.cloned-inputs .input-lang').not('.hide').find('input').not().each(function () {
                $(this).closest('.form-group').find('.input-lang.hide input').val($(this).val());
            });
        });
    };

    return {
        //main function to initiate the module
        init: function(url) {
            _deleteUrl = url;

            $('#add-attribute').on('click', function () {
                var clone = $('.attribute').last().clone();

                $.each(clone.find('input'), function( index, value ) {
                    var key = value.name.match(/\d+/g);
                    value.name = value.name.replace(/\d+/g, key + 1);
                });
                clone.find('input').val('');
                clone.addClass('new-clone');
                $('.cloned-inputs').append(clone);

                initRemove();
            });


            var button = '<button type="button" data-original-title="Are you sure ?" title="" class="btn red btn-sm clone-remove"> Remove </button>';
            var addCloneButtons = function () {
                div = $('.clone-buttons');
                div.find('.button-block').append(button);

                initRemove();
            };

            addCloneButtons();
            dragAndDropPosition();
            copyInput();
        }

    };
}();