var Couriers = function() {
    var count = 0;
    var generateInput = function() {
        $(document).on('click','#add_couriers_price',function () {
            var inputs = $("#prices_lists").find($("input") );
            count = inputs.length-1;

            count++;
            $.ajax({
                url: '/couriers/generatePrice',
                data : {key:count},
                type: "post",
                success: function(response){
                    if (response.success) {
                        $('#prices_lists').append(response.html);
                        App.initAjax();
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });

        $(document).on('click','#add_couriers_postcode',function () {
            var inputs = $("#postcodes_lists").find($("input") );
            count = inputs.length-1;

            count++;
            $.ajax({
                url: '/couriers/generatePostCode',
                data : {key:count},
                type: "post",
                success: function(response){
                    if (response.success) {
                        $('#postcodes_lists').append(response.html);
                        App.initAjax();
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });
    };

    var removeInput = function() {
        $(document).on('click','.delete_couriers_price',function () {
            $(this).parent().remove();
        });
        $(document).on('click','.delete_couriers_postcode',function () {
            $(this).parent().remove();
        });
    };

    var listSortable = function() {
        $('#couriersTable tbody').sortable({
            // empty box effect on dropping
            placeholder: "ui-state-highlight",
            // element class
            over: function( event, ui ) {
                ui.item.addClass('dropping');
            },
            // set new array order
            update: function( event, ui ) {
                var sorted = $(this).sortable('toArray');
                // console.log(sorted);
                $.ajax({
                    url: '/couriers/sort',
                    type: 'post',
                    data: { newOrder: sorted },
                    error: function(){
                        console.log('err');
                    }
                });
                ui.item.removeClass('dropping');
            },
            // update db
            stop: function( event, ui ) {
            },
        }).disableSelection();
    };

    $(document).on('change', '#courier-type', function (e) {
        var self = $(this);
        $('.courier-form').addClass('hide');
        $('.courier-form.' + self.val()).removeClass('hide');
    });

    return {
        //main function to initiate the module
        init: function () {
            generateInput();
            removeInput();
            listSortable();
        }
    };
}();
