var Inventory = function () {

    /**
     * Init quantity change events
     */
    var handleQuantity = function () {
        $(document).off('input', 'input[name^="quantity["]').on('input', 'input[name^="quantity["]', function () {

            var self = $(this);
            var value = Number(self.val());
            var existingQuantity = Number(self.closest('.quantity-setup').attr('data-quantity'));

            // Recalculate quantity
            setTimeout(function() {
                var newQuantity = recalculateQuantity({
                    element: self,
                    value: value,
                    quantity: existingQuantity
                });

                updateQuantityPreview(self, existingQuantity, newQuantity);
            }, 100);
        });

        $(document).on('click', '.quantity-setup .input-group-btn .btn', function() {
            var self = $(this).closest('.quantity-setup').find('input[name^="quantity["]').first();
            self.trigger('input');
        });
    };

    /**
     * Update quantity preview
     *
     * @param element
     * @param existingQuantity
     * @param newQuantity
     */
    var updateQuantityPreview = function (element, existingQuantity, newQuantity) {
        element.closest('tr').find('.quantity-preview-new').html('');
        element.closest('tr').find('.save').addClass('disabled');
        if (newQuantity != existingQuantity) {
            element.closest('tr').find('.quantity-preview-new').html(' ->' + newQuantity);
            element.closest('tr').find('.save').removeClass('disabled');
        }
    };

    /**
     * Recalculate quantity regarding dependencies
     *
     * @param options
     * @returns {*}
     */
    var recalculateQuantity = function (options) {
        var dependency = options.element.closest('.quantity-setup').find('input[name="dependency"]').parent('.active').find('input').val();
        var id = options.element.closest('.quantity-setup').attr('data-id');

        if (dependency == 'add') {
            var value = options.quantity + options.value;
        } else {
            var value = options.value;
        }

        $(document).find('input[name="quantity_value[' + id + ']"]').val(value);

        return value;
    };

    /**
     * Update product quantity
     */
    var update = function () {
        $(document).on('click', '.quantity-setup .save', function() {
            var self = $(this);
            var id = $(this).closest('.quantity-setup').attr('data-id');
            var inputPreview = $(this).closest('.quantity-setup').find('input[name="quantity[' + id + ']"]');
            var inputHidden = $(this).closest('.quantity-setup').find('input[name="quantity_value[' + id + ']"]');
            var quantity = inputHidden.val();

            $.ajax({
                type: "PUT",
                url: '/inventory/' + id,
                data: {
                    quantity: quantity
                },
                dataType: 'json',
                success: function (response) {
                    if (! response.success) {
                        Notification.toastr().error('', response.error);
                    }

                    // Set new quantity information
                    self.addClass('disabled');
                    inputHidden.val(quantity);
                    self.closest('.quantity-setup').attr('data-quantity', quantity);
                    inputPreview.val('');
                    self.closest('tr').find('.quantity-preview-new').html('');
                    self.closest('tr').find('.quantity-preview').html(quantity);
                }

            });
        });
    };

    return {
        init: function () {
            handleQuantity();
            update();
        }
    };
}();

$(document).ready(function () {
    Inventory.init();
});