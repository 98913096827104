var Dashboards = function(chartsList) {

    var init = function(chartsList) {
        this.chartsList = chartsList;
        this.charts = [];
        google.charts.load('current', {'packages':['corechart']});
        google.charts.setOnLoadCallback(prepareCharts);
        handleResize();
    };

    var prepareCharts = function() {
        for (index = 0; index < this.chartsList.length; ++index) {
            var data = google.visualization.arrayToDataTable(this.chartsList[index]["chartData"]);

            var options = {
                title: this.chartsList[index]["options"]["title"],
                hAxis: {
                    title: this.chartsList[index]["options"]["x-title"],
                    titleTextStyle: {color: '#333'}
                },
                vAxis: {
                    title: this.chartsList[index]["options"]["y-title"],
                    minValue: 0
                }
            };

            var selector = this.chartsList[index]["options"]["id"];
            var chart = new google.visualization.AreaChart(document.getElementById(selector));
            chart.draw(data, options);
            this.charts.push({
                "data" : data,
                "options" : options,
                "chart" : chart
            });
        }
    };

    var drawCharts  = function() {
        for (index = 0; index < this.charts.length; ++index) {
            this.charts[index].chart.draw(
                this.charts[index].data,
                this.charts[index].options
            );
        }
    };

    var handleResize = function() {
        $(window).resize(function() {
            drawCharts();
        });
    };

    return {
        //main function to initiate the module
        init: function(chartsList) {
            init(chartsList);
        }

    };
}();