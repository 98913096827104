var SlidersPage = function() {
    return {
        init: function (id) {
            $('body').on('click', '.slider-remove', function () {
                var attachmentId = $(this).data('attachment-id');
                var self = $(this);
                $.ajax({
                    url: '/attachments/' + attachmentId,
                    method: "delete",
                    data: {
                        model: 'sliders',
                        model_id: id
                    },
                    success: function(response) {
                        if (response.success) {
                            self.closest('.row').remove();
                        } else {
                            Notification.toastr().error(response.error);
                        }
                    }
                });
            });

            $('body').on('click', '.slider-save', function () {
                var attachmentId = $(this).data('attachment-id');
                var self = $(this);
                var form = self.closest('form');
                $.ajax({
                    url: '/sliders/'+ id + '/attachment/' + attachmentId,
                    method: "POST",
                    data: form.serializeArray(),
                    success: function(response) {
                        if (response.success) {
                            Notification.toastr().success('Saved!');
                        } else {
                            Notification.toastr().error(response.error);
                        }
                    }
                });
            });

            var dropzone = $("form#sliders-dropzone").dropzone({
                init: function () {
                    this.on('complete', function (file) {
                        var response = JSON.parse(file.xhr.response);
                        $('.slider-attachments').append(response.html);
                        $('.minicolors-input input').minicolors();
                        $('.date-picker').datepicker({
                            format: 'yyyy-mm-dd',
                            autoclose: true
                        });

                        $("input[type=checkbox]:not(.toggle, .md-check, .md-radiobtn, .make-switch, .icheck), input[type=radio]:not(.toggle, .md-check, .md-radiobtn, .star, .make-switch, .icheck)").uniform();
                    });
                }
            });

            $('.slider-attachment-dropzone').dropzone(
                {
                    init: function () {
                        var form = this;
                        this.on('complete', function (file) {
                            var fileInfo = (file.xhr.response);
                            var parse = JSON.parse(fileInfo);
                            var fileName = (parse.attachment.file_name);

                            var responseURL = (file.xhr.responseURL);
                            var res = responseURL.replace("upload", "get");
                            var imageURL = encodeURI((res+'?model=sliders&modelId=1'+'&file_name='+fileName));

                            $.ajax({
                                url: imageURL,
                                method: "get",
                                success: function (response) {
                                    $(".dz-preview").hide();
                                    $(form.element).css('background-image', 'url('+response+')').css('background-size', 'cover').css('background-position', 'center').css('background-repeat', 'no-repeat');
                                }
                            });
                        });
                    },
                }
            );

            $( ".sortable" ).sortable({
                axis: "y",
                cursor: "move",
                delay: 50,
                update: function( event, ui ) {
                    var position = ui.item.index();
                    var id = ui.item.attr('data-id');
                    $.ajax({
                        url: '/sliders/'+ 1 + '/attachment/' + id,
                        method: "POST",
                        data: {
                            position: position+1
                        },
                        success: function(response) {
                            if (response.success) {
                            } else {
                                Notification.toastr().error(response.error);
                            }
                        }
                    });
                }
            });

        }
    }
}();
