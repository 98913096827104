var Lookbooks = function() {

    var _imagesTemplate;
    var _productsTemplate;

    var setImagesTemplate = function () {
        _imagesTemplate = $('#layout-images-template');
        $('#layout-images-template').remove();
        _imagesTemplate.removeAttr('id');
    };

    var setProductsTemplate = function () {
        _productsTemplate = $('#layout-products-template');
        $('#layout-products-template').remove();
        _productsTemplate.removeAttr('id');
    };

    // BEGINNING Part responsible for images
    var handleImageAssign = function() {
        $('.assign-image').popover({
            html: true,
            placement: 'top',
            content: $("#image-upload-form"),
            trigger: 'manual'
        });

        $(document).on('click', '.assign-image', function(){
            $(this).popover('toggle').off('show.bs.popover').on('show.bs.popover', function () {

            });
        });

        handleImageUploadForm();
    };

    // subimts form and changes background image
    var handleImageUploadForm = function () {
        $("#image-upload-form").on('submit', function(e){
            e.preventDefault();

            var lookbook_id = $(this).closest(".portlet-body").data("lookbook-id");
            var formData = new FormData(this);
            var block =  $(this).closest('.block');
            var formUrl = '/lookbooks/' + lookbook_id + "/" + block.data('block') + "/images";
            var popover = block.find(".assign-image");

            $.ajax({
                url : formUrl,
                data : formData,
                type: "POST",
                cache:false,
                contentType: false,
                processData: false,
                success:function(response)
                {
                    if (response.success) {
                        block.css("background-image", "url('" + response.attachment_url + "')");
                        popover.popover('toggle');
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
            return false;
        });
    };
    // END Part responsible for images

    // BEGINNING Part responsible for products

    // Make circles draggable
    var makeProductsDraggable = function() {
        $( ".block .circle" ).destroy();
        $( ".block .circle" ).draggable({
            addClasses: false,
            containment: "parent",
            start: function (event, ui) {
                var block = $(this).closest(".block");
                var deleteButton = block.find(".delete-product");

                // makes delete button visible
                showMine(deleteButton);
            },
            stop: function( event, ui ) {
                var block = $(this).closest(".block");
                var deleteButton = block.find(".delete-product");
                var lookbookProduct = $(this).closest(".circle");
                var lookbookProductId = lookbookProduct.data("value");

                hideMine(deleteButton);

                var x = ui.position.left;
                var y = ui.position.top;

                //check if needs deletion
                if (
                    (x > (block.width() - 20 - 25))
                    && (y > (block.height() - 14 - 25))
                ) {
                    deleteLookbookProduct(lookbookProductId)
                } else {
                    var left = Math.round(x / block.width() * 100);
                    var top = Math.round(y / block.height() * 100);

                    updateLookbookProduct(lookbookProductId, left, top);
                }
            }
        });
    };

    var showMine = function(domObject){
        if (domObject.hasClass("hide")) {
            domObject.removeClass("hide");
        }
    };

    var hideMine = function(domObject) {
        if (! domObject.hasClass("hide")) {
            domObject.addClass("hide");
        }
    };

    var deleteLookbookProduct = function(lookbookProductId) {
        var circle = $("#lookbook-product-" + lookbookProductId);

        $.ajax({
            url : "/lookbooks/products/" + lookbookProductId,
            data : {},
            type: "DELETE",
            cache:false,
            contentType: false,
            processData: false,
            success:function(response)
            {
                if (response.success) {
                    circle.remove();
                    Notification.toastr().info('', "Item removed");
                } else {
                    Notification.toastr().error('', response.error);
                }
            }
        });
    };

    var updateLookbookProduct = function(lookbookProductId, left, top) {
        $.ajax({
            url : "/lookbooks/products/" + lookbookProductId,
            data : {
                "top" : top,
                "left" : left
            },
            type: "PUT",
            success:function(response)
            {
                if (response.success) {
                    Notification.toastr().info("", "Postition updated");
                } else {
                    Notification.toastr().error('', response.error);
                }
            }
        });
    };

    var handleProductAssign = function() {
        $('.select-product').popover({
            html: true,
            placement: 'top',
            content: $("#product-select-form"),
            trigger: 'manual'
        });

        $(document).on('click', '.select-product', function(){
            $("#select2-ajax-products").select2("val", "");
            $(this).popover('toggle').off('show.bs.popover').on('show.bs.popover', function () {
            });
        });

        $("#product-select-form").on('submit', function(e){
            e.preventDefault();

            var lookbook_id = $(this).closest(".portlet-body").data("lookbook-id");
            var formData = $(this).serialize();
            var block = $(this).closest('.block');
            var blockId = block.data('block');
            var formUrl = '/lookbooks/' + lookbook_id + "/" + blockId + "/products";
            var popover = block.find(".select-product");

            $.ajax({
                url : formUrl,
                data : formData,
                type: "POST",
                success:function(response)
                {
                    if (response.success) {
                        block.append(response.html_to_add);
                        popover.popover('toggle');
                        makeProductsDraggable();
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
            return false;
        });
    };
    // END Part responsible for products

    return {
        //main function to initiate the module
        init: function (options) {
            setImagesTemplate();
            setProductsTemplate();

            handleProductAssign();
            handleImageAssign();

            makeProductsDraggable();
        }
    };
}();