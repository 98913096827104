/**
 * Returns page functionality
 *
 * @author Antanas Kontrimas <antanas@koralis.lt>
 */

var Returns = function() {

    var collectID = function() {
        $('#generate-xlsx').on('click', function() {
            var selectedReturns = [];

            $('.dataTable tbody .checked input[name="id[]"]').each(function() {
                selectedReturns.push($(this).val());
            });

            $.ajax({
                url: '/returns/generateXLSX',
                data: {selected_rows: selectedReturns},
                type: 'post',
                success: function(response) {
                    if (response.success) {
                        var download_button= $('<a href="/download_xlsx/'+response.file_id+'" class="btn btn-circle btn-info" id="download-xlsx">Download XLSX</a>');

                        if ($("#download-xlsx").length ) {
                            $("#download-xlsx").attr("href", "/download_xlsx/"+response.file_id+"");
                        } else {
                            $(download_button).insertAfter('#generate-xlsx');
                        }
                        Notification.toastr().success('', "XLSX generated");

                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });

        $('#generate-sepa').on('click', function() {
            var selectedReturns = [];
            var already_has_sepa = [];

            $('.dataTable tbody .checked input[name="id[]"]').each(function() {
                selectedReturns.push($(this).val());
                if ($('input[name="sepa-'+ $(this).val() +'"]').length) {
                    already_has_sepa.push($(this).val());
                }
            });

            if (already_has_sepa.length) {
                var check = confirm('Grąžinimui nr. ' + already_has_sepa.join(', ') + ' jau buvo generuotas SEPA failas, ar tikrai norite generuoti?');

                if (check == false) {
                    return;
                }
            }

            $.ajax({
                url: '/returns/generateSepa',
                data: {selected_rows: selectedReturns},
                type: 'post',
                success: function(response){
                    $.each(response, function(i, item) {
                        if (item.success) {
                            var download_button = $('<a href="/download_sepa/' + item.file_id + '" class="btn btn-cicle btn-info" id="download-sepa-' + i + '">Download SEPA ' + i + '</a>');

                            if ($('#download-sepa-' + i).length) {
                                $('#download-sepa-' + i).attr('href', '/download_sepa/' + item.file_id);
                            } else {
                                $(download_button).insertAfter('#generate-sepa');
                            }

                            Notification.toastr().success('', 'SEPA generated');
                        } else  {
                            Notification.toastr().error('', item.error);
                        }
                    });
                }
            });
        });

        $('#generate-csv').on('click', function() {

            var selectedProducts = [];

            $('.dataTable tbody .checked input[name="id[]"')
                .each(function () {
                    selectedProducts.push($(this).val());
                });

            $.ajax({
                url: '/returns/generateCsv',
                data : {selected_rows:selectedProducts},
                type: "post",
                success: function(response){
                    if (response.success) {
                        var download_button= $('<a href="/download_csv/'+response.file_id+'" class="btn btn-circle btn-info" id="download-csv">Download CSV</a>');

                        if ($("#download-csv").length ) {
                            $("#download-csv").attr("href", "/download_csv/"+response.file_id+"");
                        } else {
                            $(download_button).insertAfter('#generate-csv');
                        }
                        Notification.toastr().success('', "CSV generated");

                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });

        });
    };

    return {
        init: function() {
            collectID();
        }
    }
}();
