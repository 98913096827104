/**
 * Custom form fields
 *
 * @author Dominykas Tijūnaitis <dominykas@koralis.lt>
 */
var Form = function () {
    var handleTinyMCE = function () {
        tinymce.init({
            selector: '.tinymce',
            theme: 'modern',
            plugins: [
                'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                'searchreplace wordcount visualblocks visualchars code fullscreen',
                'insertdatetime media nonbreaking save table contextmenu directionality',
                'emoticons template paste textcolor colorpicker textpattern imagetools'
            ],
            toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
            toolbar2: 'print preview media | forecolor backcolor emoticons',

        });
    };

    var handleSelect2 = function() {
        $.fn.select2.defaults.set("theme", "bootstrap");

        $(".select2").select2({
            allowClear: true,
            width: null
        });

        $(".select2, .select2-multiple, .select2-allow-clear, .js-data-example-ajax").on("select2:open", function() {
            if ($(this).parents("[class*='has-']").length) {
                var classNames = $(this).parents("[class*='has-']")[0].className.split(/\s+/);

                for (var i = 0; i < classNames.length; ++i) {
                    if (classNames[i].match("has-")) {
                        $("body > .select2-container").addClass(classNames[i]);
                    }
                }
            }
        });
    };

    var handleMultiSelect = function () {
        $('.multi-select').multiSelect({
            selectableOptgroup: true
        });
    };

    var handleBootstrapMaxlength = function() {
        $('.maxlength').maxlength({
            alwaysShow: true,
            limitReachedClass: "label label-danger"
        })
    };

    var handleDatePickers = function () {

        if (!jQuery().datepicker) {
            return;
        }

        $('.date-picker').datepicker({
            format: 'yyyy-mm-dd',
            autoclose: true
        });
    };

    var handleColorPickers = function () {
        $('.minicolors-input input').minicolors();
    };

    var handleDatetimePicker = function () {
        if (!jQuery().datetimepicker) {
            return;
        }

        $(".form_datetime").datetimepicker({
            autoclose: true,
            format: "yyyy-mm-dd hh:ii"
        });
    };

    var handleSummernode = function () {
        $('.default_editor').summernote({
            height: 150,
            onImageUpload: function(files) {
                sendFile(files[0], this);
            }
        });
    };

    /**
     * Sends file to backoffice and adds its url to editor field
     *
     * @param file
     * @param summerEditor
     */
    var sendFile = function(file, summerEditor) {
        data = new FormData();
        data.append("file", file);
        data.append("model", "custom_editor");
        $.ajax({
            data: data,
            type: "POST",
            url: "/upload",
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {
                if (typeof(response.imageUrl) != "undefined") {
                    $(summerEditor).summernote('insertImage', response.imageUrl);
                }
            }
        });
    };


    /**
     * Handles the active/inactive checkbox for daterange
     */
    var handleDateRanges = function () {
        $(document).on('click', '.enable-ranges input[type="checkbox"]', function () {
            var checkbox = $(this);
            $.uniform.update(checkbox);

            if (checkbox.is(':checked')) {
                checkbox.closest('.range').find('.input-daterange input[type="daterangepicker"]').prop('disabled', false);
            } else {
                checkbox.closest('.range').find('.input-daterange input[type="daterangepicker"]').prop('disabled', true);
            }
        });
    };

    var handleDateTimeRanges = function () {
        $(document).on('click', '.enable-ranges input[type="checkbox"]', function () {
            var checkbox = $(this);
            $.uniform.update(checkbox);

            if (checkbox.is(':checked')) {
                checkbox.closest(".datetime-div").find('.range').find('input[type="datetimerangepicker"]').prop('disabled', false);
            } else {
                checkbox.closest(".datetime-div").find('.range').find('input[type="datetimerangepicker"]').prop('disabled', true);
            }
        });
    };

    return {
        init: function () {

            handleColorPickers();
            handleTinyMCE();
            handleSelect2();
            handleMultiSelect();
            handleBootstrapMaxlength();
            handleDatePickers();
            handleDatetimePicker();
            handleSummernode();
            handleDateRanges();
            handleDateTimeRanges();

        },
        handleTinyMCE: function () {
            handleTinyMCE();
        },
        handleSelect2: function () {
            handleSelect2();
        },
        handleMultiSelect: function () {
            handleMultiSelect();
        },
        handleBootstrapMaxlength: function () {
            handleBootstrapMaxlength();
        },
        handleDatePickers: function () {
            handleDatePickers();
        },
        handleDatetimePicker: function () {
            handleDatetimePicker();
        },
        handleSummernode: function () {
            handleSummernode();
        }
    };
}();


jQuery(document).ready(function() {
    Form.init();
});
