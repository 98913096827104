var Attachments = function () {
    var attachmentsRemoving = function () {
        $('body').on('click', '.remove-attachment', function () {
            var self = $(this);

            $.ajax({
                url: '/attachments/' + self.data('attachment-id'),
                method: "delete",
                data: {
                    model: self.data('model'),
                    model_id: self.data('model-id'),
                    shop_id: self.data('shop-id')
                },
                success: function (response) {
                    if (response.success) {
                        $(document).find('.thumbnail[data-attachment-id="' + self.data('attachment-id') + '"]').remove();
                        self.remove();
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        })
    };

    return {
        init: function () {
            attachmentsRemoving();
        }
    }
}();

jQuery(document).ready(function () {
    Attachments.init();
});