/**
 * Admin language js
 *
 * @author    Julius Beniušis <julius.beniusis@koralis.lt>
 */

var Language = function () {

    //BEGIN: GLOBAL VARIABLES
    var LANGUAGES = [];
    var DEFAULT;
    // END: GLOBAL VARIABLES


    /**
     * Set default language
     *
     * @param language
     */
    var setDefault = function (language) {
        if (language.default == 1) {
            DEFAULT = language;
        }
    };

    var changeFields = function () {
        $(document).on('click', '.languages a', function(e){
            e.preventDefault();

            var btn = $(this);
            var language_id = btn.data('language-id');

            // Classes manipulation
            $(document).find('.languages .btn').removeClass('active');
            btn.addClass('active');

            // Set inputs
            $(document).find(".input-lang").addClass('hide');
            $(document).find(".lang-"+language_id+"").removeClass('hide');

            return false;
        });
    };


    /**
     * Module return on call
     */
    return {
        /**
         * Main function to initiate the module
         */
        init: function () {
            changeFields();
        },
        changeFields: function () {
            changeFields();
        },
        getDefault: function () {
            return DEFAULT;
        },
        get: function () {
            return LANGUAGES;
        },
        set: function (language) {
            LANGUAGES.push(language);
            setDefault(language);
        },
        getDatablesTranslations: function (locale) {

            var languages = [
                {
                    'locale':           "lt",
                    "sEmptyTable":      "Lentelėje nėra duomenų",
                    "sInfo":            "<span class='seperator'>|</span> Rasta _TOTAL_ įrašų",
                    "sInfoEmpty":       "Rodomi įrašai nuo 0 iki 0 iš 0",
                    "sInfoFiltered":    "(atrinkta iš _MAX_ įrašų)",
                    "sInfoPostFix":     "",
                    "sInfoThousands":   " ",
                    "sLengthMenu":      "<span class='seperator'>|</span>Rodyti _MENU_ įrašus",
                    "sLoadingRecords":  "Įkeliama...",
                    "sProcessing":      "Apdorojama...",
                    "sSearch":          "Ieškoti:",
                    "sThousands":       "",
                    "sUrl":             "",
                    "sZeroRecords":     "Įrašų nerasta",

                    "oPaginate": {
                    "sFirst": "Pirmas",
                        "sPrevious": "Ankstesnis",
                        "sNext": "Tolimesnis",
                        "sLast": "Paskutinis",
                        "page": "Puslapis",
                        "pageOf": "iš"
                    }
                },
                {
                    'locale': 'en',
                    // metronic spesific
                    "metronicGroupActions": "_TOTAL_ records selected:  ",
                    "metronicAjaxRequestGeneralError": "Could not complete request. Please check your internet connection",

                    // data tables spesific
                    "lengthMenu": "<span class='seperator'>|</span>View _MENU_ records",
                    "info": "<span class='seperator'>|</span>Found total _TOTAL_ records",
                    "infoEmpty": "No records found to show",
                    "emptyTable": "No data available in table",
                    "zeroRecords": "No matching records found",
                    "paginate": {
                        "previous": "Prev",
                        "next": "Next",
                        "last": "Last",
                        "first": "First",
                        "page": "Page",
                        "pageOf": "of"
                    }
                }
            ];

            for (index = 0; index < languages.length; ++index) {
                if (languages[index]['locale'] == locale) {
                    return languages[index];
                }
            }

            return languages[1];
        }
    };
}();

jQuery(document).ready(function() {
    Language.init();
});
