var UsersPage = function() {
    return {
        init: function () {
            $('body').on('click', '.generate-password', function () {
                $('body').find('.generated-password').val(randomStringGenerator());
            });

            $('body').on('click', '#remove-profile-pic', function () {
                var img = $('body').find('#profile-img');
                var self = $(this);
                $.ajax({
                    url: '/attachments/' + img.data('attachment-id'),
                    method: "delete",
                    data: {
                        model: img.data('model'),
                        model_id: img.data('model-id')
                    },
                    success: function (response) {
                        if (response.success) {
                            img.attr('src', 'http://www.placehold.it/200x150/EFEFEF/AAAAAA&amp;text=no+image');
                            self.remove();
                        } else {
                            Notification.toastr().error('', response.error);
                        }
                    }
                });

            })
        }
    }
}();
