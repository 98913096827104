/**
 * Orders page functionality
 *
 * @author Gediminas Radzevičius <gediminas@koralis.lr>
 */


var order = function() {

    // User Events

    var cancelButtonClick = function() {
        //$('.order-cancel-button').on('click', function(e){
        //    e.preventDefault();
        //    showCancelPopup();
        //    return false;
        //})
    };

    var firstDeleteProductButtonClick = function() {
        $('.product-delete-step-1').on('click', function(e){
            var productId = $(this).closest('.product-row').data("product-id");
            $('#productDeleteModal').data('product-id', productId);
        });
    };

    /**
     * On button click in modal this function sets specific product quantity to 0
     * and commits form. That way API deletes this product from order.
     */
    var secondDeleteProductButtonClick = function() {
        $('.product-delete-step-2').on('click', function(e) {
            //BEGIN: Get form data
            var form = $('.products-portlet form');
            var formData = form .serializeArray().reduce(function(obj, item) {
                obj[item.name] = item.value;
                return obj;
            }, {});
            var formUrl = form.attr('action');
            //END: Get form data

            //BEGIN: Change form data quantities
            var productId = $('#productDeleteModal').data("product-id");
            var fieldName = "quantity[" + productId +"]";

            formData[fieldName] = 0;
            //END: Change form data quantities

            //BEGIN: Commit changed form
            $('#productDeleteModal .loading').show();

            $.ajax({
                type: "PUT",
                url: formUrl,
                data: formData,
                success: function (response) {
                    if (response.success) {
                        if (typeof response.redirect_url != "undefined" && response.redirect_url != "undefined") {
                            window.location = response.redirect_url;
                        } else {
                            location.reload();
                        }
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });

            //END: Commit changed form
        });
    };

    // Functions

    //var showCancelPopup = function() {
    //    $('.cancel-order-form').show();
    //};

    return {
        init: function() {
            //cancelButtonClick();
            firstDeleteProductButtonClick();
            secondDeleteProductButtonClick();
        }
    }
}();
