/**
 * Pages page functionality
 *
 * @author Antanas Kontrimas <antanas@koralis.lt>
 * @author Anton Šumilov <anton@koralis.lt>
 */

var Pages = function () {
    var columnId;
    var moduleId;
    var layoutPresets;

    var setVariables = function (presets) {
        layoutPresets = presets;
    };

    /**
     * Handle module select list
     */
    var handleModules = function () {
        $(document).on('change', '#module', function () {
            var module = $(this).val();
            hideAllModules();
            disableRedirect();

            switch (module) {
                case 'text':
                    initModuleDefault();
                    initModuleText();
                    initSeo();
                    break;
                case 'delivery':
                    initModuleDefault();
                    initModuleText();
                    initSeo();
                    break;
                case 'settings':
                    initModuleDefault();
                    initModuleSettings();
                    break;
                case 'catalogue':
                    initModuleDefault();
                    initModuleCatalogue();
                    initModuleLayout();
                    initSeo();
                    break;
                case 'brand':
                    initModuleDefault();
                    initModuleBrand();
                    break;
                case 'group':
                    initModuleDefault();
                    initModuleGroup();
                    break;
                case 'blog':
                    initModuleDefault();
                    initModuleBlog();
                    break;
                case 'layout':
                    initModuleDefault();
                    initModuleLayout();
                    initSeo();
                    break;
                case 'terms_conditions':
                    initModuleDefault();
                    initModuleText();
                    initSeo();
                    break;
                case 'header':
                    initModuleDefault();
                    initModuleText();
                    initGenderHeader();
                    break;
                case 'before_header':
                    initModuleDefault();
                    initModuleText();
                    initGenderHeader();
                    break;
                case 'brands':
                    initModuleDefault();
                    initSeo();
                    break;
                case 'brands_list':
                    initModuleDefault();
                    initSeo();
                    break;
                case 'text_block':
                    initModuleText();
                    break;
                case 'homepage':
                    initModuleLayout();
                    initSeo();
                    break;
                case 'footer':
                    initModuleLayout();
                    initModuleText();
                    break;
                case 'product_text':
                    initModuleLayout();
                    initModuleText();
                    break;
                case 'delivery_text':
                    initModuleText();
                    initIconTicks();
                    initModuleDeliveryText();
                    break;
                case 'return_text':
                    initModuleText();
                    initIconTicks();
                    initModuleReturnText();
                    break;
                default:
                    break;
            }
        });
    };

    /**
     * Hide all modules blocks when module is changed
     */
    var hideAllModules = function () {
        $(document).find('.module').addClass('hidden');
        $(document).find('.layout-tab').addClass('hidden');
        $(document).find('.seo').addClass('hidden');
    };

    var disableRedirect = function () {
        $(document).find('select[name="redirect[catalogue]"]').prop("disabled", true);
        $(document).find('select[name="redirect[group]"]').prop("disabled", true);
        $(document).find('select[name="redirect[brand]"]').prop("disabled", true);
    };

    var initModuleDefault = function () {
        $(document).find('.module.default').removeClass('hidden');
    };

    /**
     * Init text module
     */
    var initModuleText = function () {
        $(document).find('.module.text').removeClass('hidden');
    };

    /**
    * Init Delivery text module
    */
    var initModuleDeliveryText = function () {
      $(document).find('.module.delivery_text').removeClass('hidden');
    };

    /**
     *  Init return text module
     */
    var initModuleReturnText = function () {
        $(document).find('.module.return_text').removeClass('hidden');
    };

    /**
    * Init Delivery text module
    */
    var initIconTicks = function () {
        $(document).find('.module.icon_ticks').removeClass('hidden');
    };

    /**
     * Init settings module
     */
    var initModuleSettings = function () {
        $(document).find('.module.settings').removeClass('hidden');
    };

    /**
     * Init settings module
     */
    var initModuleCatalogue = function () {
        $(document).find('select[name="redirect[catalogue]"]').prop("disabled", false);
        $(document).find('.module.catalogue').removeClass('hidden');
    };

    /**
     * Init settings module
     */
    var initModuleBrand = function () {
        $(document).find('select[name="redirect[brand]"]').prop("disabled", false);
        $(document).find('.module.brand').removeClass('hidden');
    };

    /**
     * Init settings module
     */
    var initModuleGroup = function () {
        $(document).find('select[name="redirect[group]"]').prop("disabled", false);
        $(document).find('.module.group').removeClass('hidden');
    };

    /**
     * Init settings module
     */
    var initModuleBlog = function () {
        $(document).find('select[name="redirect[blog]"]').prop("disabled", false);
        $(document).find('.module.blog').removeClass('hidden');
    };

    /**
     * Init layout module
     */
    var initModuleLayout = function () {
        $(document).find('.layout-tab').removeClass('hidden');
    };

    var initSeo = function () {
        $(document).find('.seo').removeClass('hidden');
    };

    var initGenderHeader = function () {
        $(document).find('.header').removeClass('hidden');
    }

    /**
     * Add row to layout
     */
    var addRow = function () {
        $(document).off('click', '.row_add').on('click', '.row_add', function () {
            var type = $(document).find('.row_type .btn.active input').data('type');

            var columns = layoutPresets[type];

            $.ajax({
                type: "PUT",
                url: '/cms/' + getPageID() + '/row/add',
                data: {
                    columns: columns
                },
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        //reloadPage();
                        $(document).find('#layout-rows').append(response.html);
                    }
                }

            });
        });
    };

    var reloadPage = function () {
        $.ajax({
            url: '/' + self.options.resource + '/' + getActiveNode() + '/edit',
            type: "get",
            success: function (response) {
                if (response.success) {
                    $('#tree-edit-form').html(response.html);
                    App.initAjax();
                    Form.init();
                    Pages.handleModules();
                    $('.nav-tabs a[href="#layout"]').tab('show');
                } else {
                    Notification.toastr().error('', response.error);
                }
            }
        });
    };

    var addModule = function () {
        $(document).off('click', '#attach-module').on('click', '#attach-module', function () {
            var data = $(this).closest('.modal-content').find('form :input').serializeArray();

            data.push({
                name: 'column_id',
                value: columnId
            });

            $.ajax({
                type: "post",
                url: '/cms/new/module',
                data: data,
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        $('#module-modal').modal('hide');
                        reloadPage();
                    }
                }
            });
        });

        $(document).off('click', '.add-module-column').on('click', '.add-module-column', function () {
            columnId = $(this).attr("data-value");
            $('#module-modal').modal('toggle');
            selectFormList();
            selectFormFilter();
        });
    };

    var editModule = function() {
        $(document).off('click', '.edit-module-column').on('click', '.edit-module-column', function () {
            //gets module id and shows modal
            columnId = $(this).attr("data-value");
            $('#edit-module-modal').modal('toggle');
            $('#edit-module-modal .loading').show();
            $('#edit-module-modal .form').html("");

            // loads module form
            $.ajax({
                type: "get",
                url: '/cms/column/' + columnId,
                data: {},
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        //adds module form to modal
                        $('#edit-module-modal .form').html(response.form_html);
                        $('#edit-module-modal .loading').hide();

                        // BEGINNING shows needed form part
                        var selected = $('#edit-module-modal .form .module-select').val();
                        $('.select-lists').hide();
                        $('.'+selected).show();

                        var selected_id = $('.select-lists.product_lists:last').find('#product_lists_type').val();

                        $('.product_lists_type_block:last .form-group').hide();
                        $('.type-'+ selected_id).show();

                        // END shows needed form part
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });
    };

    var submitEditForm = function () {
      $(document)
          .off('click', '#edit-module-modal .modal-footer .btn.green')
          .on("click", "#edit-module-modal .modal-footer .btn.green", function () {
              var form = $(this).closest("#edit-module-modal").find("form");
              var form_url =  form.attr("action");
              var form_data = form.serialize();

              $('#edit-module-modal .form').html("");
              $('#edit-module-modal .loading').show();

              $.ajax({
                  url: form_url,
                  type: 'post',
                  dataType: 'json',
                  data: form_data,
                  success: function(response) {
                      if (response.success == true) {
                          $('#edit-module-modal .loading').hide();
                          $('#edit-module-modal').modal('toggle');
                          $("#column_" + response.data["id"]).html("Module: " + response.data["module_type"] + ", Device: " + response.data['devices']);

                      } else {
                          Notification.toastr().error('', response.error);
                      }
                  }
              });
      });
    };

    var moduleDelete = function () {
        $(document).on('click', '.delete-module-column', function () {
            moduleId = $(this).attr("data-value");
            $.ajax({
                type: "post",
                url: '/cms/delete/module',
                data: {
                    data: {
                        id: moduleId
                    }
                },
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        reloadPage();
                    }
                }
            });
        });
        $(document).on('click', '.delete-module-row', function () {
            var _self = $(this);
            id = $(this).attr("data-value");
            $.ajax({
                type: "delete",
                url: 'cms/row/'+ id +'/',
                data: {
                },
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        _self.closest('.row-block').remove();
                    }
                }
            });
        });
    };


    var getActiveNode = function () {
        return self.options.activeNode;
    };


    var selectFormList = function() {
        $(document).on('change', '.module-select', function() {
            var selected = $(this).val();
            $('.select-lists').hide();
            $('.'+selected).show();
        });
    };

    var selectFormFilter = function () {
        $(document).on('change', '#product_lists_type', function() {
            var selected = $(this).val();
            $('.product_lists_type_block .form-group').hide();
            $('.type-'+selected).show();
            /*
            $('.'+selected).show();*/
        });
    };


    var initLayout = function () {

    };

    /**
    * Init layout row to be draggable
    */
    var initDraggableRow = function () {
       $('#layout-rows').sortable({
           items: ".row-block",
           opacity: 0.8,
           handle : '.portlet-title',
           coneHelperSize: true,
           placeholder: 'portlet-sortable-placeholder',
           forcePlaceholderSize: true,
           tolerance: "pointer",
           helper: "clone",
           tolerance: "pointer",
           forcePlaceholderSize: !0,
           helper: "clone",
           revert: 250, // animation in milliseconds
           update: function(event, ui) {
               var position = ui.item.index();
               var id = ui.item.attr('data-id');

               $.ajax({
                   url: 'cms/row/'+ id,
                   method: "POST",
                   data: {
                       position: position+1
                   },
                   success: function(response) {
                       if (response.success) {
                       } else {
                           Notification.toastr().error(response.error);
                       }
                   }
               });
           }
       });
    };

    var updateRow = function () {

    };

    var removeRow = function () {

    };

    /**
     * Get page ID
     *
     * @returns {*|jQuery}
     */
    var getPageID = function () {
        return $(document).find('#page').data('page');
    };

    return {
        init: function (presets) {
            setVariables(presets);
            addRow();
            addModule();
            editModule();
            submitEditForm();
        },
        handleModules: function () {
            handleModules();
            moduleDelete();
            selectFormList();
            selectFormFilter();
            initDraggableRow();
        },
    };
}();