var ProductsPage = function() {

    /**
     * Gets products with checked checkboxes
     *
     * @returns {Array}
     */
    var getSelectedProducts = function()
    {
        var selectedProducts = [];

        $('.dataTable tbody .checked input[name="id[]"')
            .each(function () {
                selectedProducts.push($(this).val());
            });

        return selectedProducts;
    };

    /**
     * Process button "Update all" click
     */
    var buttonToUpdateAll = function() {
        $('#updateAllFromFVS').on('click', function() {
            sendUpdateFromFVSRequest(['all']);
        })
    };

    /**
     * Process button "Update checked" click
     */
    var buttonToUpdateSelected = function() {
        $('#updateCheckedFromFVS').on('click', function() {
            var productsToUpdate = getSelectedProducts();
            sendUpdateFromFVSRequest(productsToUpdate);
        })
    };

    // BEGINNING private functions
    /**
     * Send request to update products
     *
     * @param ProductsToUpdate
     */
    var sendUpdateFromFVSRequest = function(ProductsToUpdate) {

        ProductsToUpdate = typeof ProductsToUpdate !== 'undefined' ? ProductsToUpdate : [];

        $.ajax({
            type: "PUT",
            url: '/fvs/update/quantities',
            data: {
                products: ProductsToUpdate
            },
            dataType: 'json',
            success: function (response) {
                if (! response.success) {
                    Notification.toastr().error('', response.error);
                } else {
                    Notification.toastr().info('', "Products updated");
                    window.location.reload();
                }
            }
        });
    };
    // END private functions

    // BEGIN MASS CATALOGUE ASSIGN
    var massCatalogueAssign = function() {
        $("body").on('click', '.add-catalogue', function () {
            massCatalogueAssignAjax('add');
        });
        $("body").on('click', '.remove-catalogue', function () {
            massCatalogueAssignAjax('remove');
        });

        massCatalogueAssignAjax = function (action) {
            var catalogues = $('.multi-catalogues-select').val();
            var products = getSelectedProducts();

            $.ajax({
                type: "POST",
                url: '/products/update_catalogues',
                data: {
                    products: products,
                    catalogues: catalogues,
                    action: action
                },
                dataType: 'json',
                success: function (response) {
                    if (! response.success) {
                        Notification.toastr().error('', response.error);
                    } else {
                        Notification.toastr().info('', "Products updated");
                        location.reload();
                    }
                }
            });
        }
    };
    // END MASS CATALOGUE ASSIGN

    // BEGIN MASS SHOP ASSIGN
    var massShopAssign = function() {
        $("body").on('click', '.add-shop', function () {
            massShopAssignAjax('add');
        });
        // $("body").on('click', '.remove-shop', function () {
        //     massShopAssignAjax('remove');
        // });

        massShopAssignAjax = function (action) {
            var shop = $('.shops-select').val();
            var products = getSelectedProducts();

            $.ajax({
                type: "POST",
                url: '/products/update_shop',
                data: {
                    products: products,
                    shop: shop,
                    action: action
                },
                dataType: 'json',
                success: function (response) {
                    if (! response.success) {
                        Notification.toastr().error('', response.error);
                    } else {
                        Notification.toastr().info('', "Products updated");
                        location.reload();
                    }
                }
            });
        }
    };
    // END MASS SHOP ASSIGN

    return {
        init : function() {
            buttonToUpdateSelected();
            buttonToUpdateAll();
            massCatalogueAssign();
            massShopAssign();
        },
        getSelectedProducts : getSelectedProducts
    }
}();