$('#discounts-generate-csv').on('click', function() {

    var selected = [];

    $('.dataTable tbody .checked input[name="id[]"')
        .each(function () {
            selected.push($(this).val());
        });

    $.ajax({
        url: '/discounts/generateCsv',
        data : {selected_rows:selected},
        type: "post",
        success: function(response){
            console.log(response)
            if (response.success) {
                var download_button= $('<a href="/discounts/download_csv/'+response.file_id+'" class="btn btn-circle btn-info" id="discounts-download-csv">Download CSV</a>');

                if ($("#discounts-download-csv").length ) {
                    $("#discounts-download-csv").attr("href", "/discounts/download_csv/"+response.file_id+"");
                } else {
                    $(download_button).insertAfter('#discounts-generate-csv');
                }
                Notification.toastr().success('', "CSV generated");

            } else {
                Notification.toastr().error('', response.error);
            }
        }
    });

});

var Discounts = function() {
    var generateInput = function() {

        var caseNum = $( "#discount_condition" ).val();
        if (caseNum == 'all_orders' || caseNum == 'over_sum' || caseNum == 'until_sum') {
            $("#discount-value-select").show();
        }
        if (caseNum == 'coupon' || caseNum == 'membershop_coupon' || caseNum == 'soundest_coupon' || caseNum == 'brand_coupon' || caseNum == 'catalogue_coupon' || caseNum == 'group_coupon') {
            displayMinimalSumInput();
            $("#coupon-code").show();
            $("#minimal_sum").show();
        } else {
            hideMinimalSumInput();
            $("#coupon-code").hide();
            generateDiscountSelect(caseNum);
        }

        if (caseNum == 'brand_coupon') {
            displayBrandSelect();
        } else {
            hideBrandSelect();
        }

        if (caseNum == 'catalogue_coupon') {
            displayCatalogueSelect();
        } else {
            hideCatalogueSelect();
        }

        if (caseNum == 'group_coupon') {
            displayGroupSelect();
        } else {
            hideGroupSelect();
        }

        $("#discount_condition").on('change', function() {
            $("#discount-table-body").empty();
            $("#input_values").empty();
            var caseNum = $( "#discount_condition" ).val();
            $(".discount-condition-value").children().hide();
            if (caseNum == 'all_orders' || caseNum == 'over_sum' || caseNum == 'until_sum') {
                $("#discount-value-select").show();
                $("#table-portlet").hide();
            } if (caseNum == 'coupon' || caseNum == 'membershop_coupon' || caseNum == 'soundest_coupon'|| caseNum == 'brand_coupon' || caseNum == 'catalogue_coupon' || caseNum == 'group_coupon') {
                $("#coupon-code").show();
                displayMinimalSumInput();
            } else {
                hideMinimalSumInput();
                generateDiscountSelect(caseNum);
            }

            if (caseNum == 'brand_coupon') {
                displayBrandSelect();
            } else {
                hideBrandSelect();
            }

            if (caseNum == 'catalogue_coupon') {
                displayCatalogueSelect();
            } else {
                hideCatalogueSelect();
            }

            if (caseNum == 'group_coupon') {
                displayGroupSelect();
            } else {
                hideGroupSelect();
            }
        });

        $(".discount-list-delete").click(function(){
            var id = $(this).attr("data-attr");
            $("#table-list-id-"+id+", #input-list-id-"+id+"").remove();
        });
        
        $("body").on('click', '#generate-coupon-code', function () {
            $("#coupon_code").val(randomStringGenerator(12));
        })
    };


    var generateDiscountSelect = function (caseNum) {
        $("#discount-"+caseNum+"-select").show().on("select2:select", function(e) {
            $("#discount-lists").show();
            $('#discount-'+caseNum+'-select > select').val('').trigger('change');

            var id = e.params.data.id;
            var title = e.params.data.text;
            if (! $("#table-list-id-"+id+", #input-list-id-"+id+"").length) {
                $.ajax({
                    type: "GET",
                    url: '/discounts/item/add',
                    data: {
                        id: id,
                        title: title
                    },
                    dataType: 'json',
                    success: function (response) {
                        if (response.success) {
                            $('#discount-table-body').append(response.html);
                            $('#input_values').append('<input type="hidden" name="'+caseNum+'[]" value="'+id+'" id="input-list-id-'+id+'">');
                        }
                    }
                });
            };

            handleDiscountRemove();
        });

        //$("#discount-"+caseNum+"-select").on("select2:unselect", function(e) {
        //    var id = e.params.data.id;
        //    $("#table-list-id-"+id+", #input-list-id-"+id+"").remove();
        //});
    };

    var handleDiscountRemove = function () {
        $(document).on('click', '.discount-list-delete', function(){
            var id = $(this).attr("data-id");
            $("#table-list-id-"+id+", #input-list-id-"+id+"").remove();
        });
    };

    var displayMinimalSumInput = function () {
        var minimalSumInput = $("#minimal_sum_input");
        if (minimalSumInput.hasClass("hidden")) {
            minimalSumInput.removeClass("hidden");
        }
        minimalSumInput.show();
    };

    var hideMinimalSumInput = function () {
        var minimalSumInput = $("#minimal_sum_input");
        if (! minimalSumInput.hasClass("hidden")) {
            minimalSumInput.addClass("hidden");
        }
        minimalSumInput.hide();
    };

    var displayBrandSelect = function () {
        var brandSelect = $("#discount-brands-select");
        if (brandSelect.hasClass("hidden")) {
            brandSelect.removeClass("hidden");
        }
        brandSelect.show();
    };

    var hideBrandSelect = function () {
        var brandSelect = $("#discount-brands-select");
        if (!brandSelect.hasClass("hidden")) {
            brandSelect.addClass("hidden");
        }
        brandSelect.hide();
    };

    var displayGroupSelect = function () {
        var groupSelect = $("#discount-groups-select");
        if (groupSelect.hasClass("hidden")) {
            groupSelect.removeClass("hidden");
        }
        groupSelect.show();
    };

    var hideGroupSelect = function () {
        var groupSelect = $("#discount-groups-select");
        if (!groupSelect.hasClass("hidden")) {
            groupSelect.addClass("hidden");
        }
        groupSelect.hide();
    };

    var displayCatalogueSelect = function () {
        var catalogueSelect = $("#discount-categories-select");
        if (catalogueSelect.hasClass("hidden")) {
            catalogueSelect.removeClass("hidden");
        }
        catalogueSelect.show();
    };

    var hideCatalogueSelect = function () {
        var catalogueSelect = $("#discount-categories-select");
        if (!catalogueSelect.hasClass("hidden")) {
            catalogueSelect.addClass("hidden");
        }
        catalogueSelect.hide();
    };

    return {
        //main function to initiate the module
        init: function () {
            generateInput();
            handleDiscountRemove();
        },

        getProducts: function (id, condition) {
            //for first time set number to first
            var count = 0;
            var page_number = 1;

            function getData(page_number) {
                // if condition not changed, continue iteration
                console.log('ssd');
                if (condition == true) {
                    $.ajax({
                        type: "GET",
                        data: {id:id, next_page: page_number},
                        url: '/groups/products/get',
                        dataType: 'json',
                        success: function (response) {
                            count = count + response.count;
                            // on response prepend values to top of the textarea

                            $('textarea[name=skus]').prepend('\n'+response.products);

                            // if next page is false iteration is done
                            if (response.next == false || response.count == 0) {
                                condition = false;
                                Notification.toastr().success('', count+" products was loaded successfuly");
                            } else {
                            // else get next page of results
                                page_number = response.next;
                                // set new cycle
                                getData(page_number);
                            }
                        }
                    });
                }
            }
            // running for the first time
            getData(page_number);
        },
        getTagProducts: function (id, condition) {
            //for first time set number to first
            var count = 0;
            var page_number = 1;

            function getDataTag(page_number) {
                // if condition not changed, continue iteration
                if (condition == true) {
                    $.ajax({
                        type: "GET",
                        data: {id:id, next_page: page_number},
                        url: '/tags/products/get',
                        dataType: 'json',
                        success: function (response) {
                            count = count + response.count;
                            // on response prepend values to top of the textarea

                            $('textarea[name=skus]').prepend('\n'+response.products);

                            // if next page is false iteration is done
                            if (response.next == false) {
                                condition = false;
                                Notification.toastr().success('', count+" products was loaded successfuly");
                            } else {
                                // else get next page of results
                                page_number = response.next;
                                // set new cycle
                                getDataTag(page_number);
                            }
                        }
                    });
                }
            }
            // running for the first time
            getDataTag(page_number);
        }
    };
}();