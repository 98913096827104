var ProductImport = function() {

    var importOn = false;

    /**
     * Creates import after form in modal is submitted
     */
    var submitFormViaAjax = function() {
        $('#productImportModal').on('hide.bs.modal', function(e) {
            stopImport();
        });
        $('#continueToImportModal').on('hide.bs.modal', function(e) {
            stopImport();
        });

        $('#productImportModal form').submit( function(e) {
            e.preventDefault();

            var postData = new FormData($(this)[0]);
            var url = $(this).attr("action");
            $.ajax(
                {
                    url : url,
                    type: "POST",
                    data : postData,
                    success:function(response, textStatus, jqXHR)
                    {
                        $('#continueToImportModal .modal-body').append(response.html_content);
                        $('#productImportModal').modal('toggle');
                        $('#continueToImportModal').modal('toggle');
                        startImporting(response.importId);
                    },
                    error: function(jqXHR, textStatus, errorThrown)
                    {
                        Notification.toastr().error('', 'Import unavailable');
                    },
                    cache: false,
                    contentType: false,
                    processData: false
                });

            return false;
        })
    };

    var startImporting = function(id) {
        startImport();
        var importRow = $('#import-' + id);
        var start = importRow.data('start');
        var count = importRow.data('count');
        var url = '/products/import/' + id;

        importRow.find('.btn-primary').prop('disabled', true);

        importRequest(url, start, count);
    };

    var cancelImporting = function(id) {
        $.ajax({
            url:'/products/import/' + id,
            data: {
            },
            type: "delete",
            success: function(response) {
                if (response.success) {
                    $('#import-' + id).remove();
                    $('#continueToImportModal').modal('toggle');
                } else {
                    Notification.toastr().error('', response.error);
                }
            }
        })
    };

    // recursion function to import one product after another
    var importRequest = function (url, start, count) {

        if (start >= count) {
            return false;
        }

        var data = {};
        if (importOn) {
            $.ajax({
                url: url,
                data: data,
                type: "post",
                success: function(response) {
                    if (response.success) {
                        start = response.start_number;
                        count = response.count_number;
                        if(response.badPageIds.length > 0) {
                            var message = 'Wrong page ids on line ' + start + '. Ids: ' ;
                            $.each(response.badPageIds, function(index, value) {
                                if(index + 1 == response.badPageIds.length) {
                                    message += value + '. ';
                                }else {
                                    message += value + ', ';
                                }
                            });
                            Notification.toastr().error('', message);
                        }
                        $('#import-' +  response.id + ' .counter').html(start + '/' + count);

                        if (start < count) {
                            importRequest(url, start, count);
                        } else {
                            location.reload();
                        }
                    } else {
                        stopImport();
                        Notification.toastr().error('', response.error);
                    }
                },
                error: function () {
                    Notification.toastr().error('','Import unnavailable');
                    stopImport();
                }
            });
        }
    };

    var continueImportButton = function ()
    {
        $('#continueToImportModal .btn-primary.continue').on('click', function() {
            var importId = $(this).closest('.import-row').data('id');
            startImporting(importId);
        })
    };

    var cancelImportButton = function ()
    {
        $('#continueToImportModal .btn-primary.cancel').on('click', function() {
            var importId = $(this).closest('.import-row').data('id');
            cancelImporting(importId);
        })
    }

    var toggleFormAfterTypeSelect = function ()
    {
        $('#import_type').change(function(){
            selectedImportType = $(this).val();

            if (selectedImportType == 'image') {
                if (! $('.products-import').hasClass('hidden')) {
                    $('.products-import').addClass('hidden');
                }
                $('.images-import').removeClass('hidden');
            } else if (selectedImportType == 'product' || selectedImportType == 'product_update') {
                if (! $('.images-import').hasClass('hidden')) {
                    $('.images-import').addClass('hidden');
                }
                $('.products-import').removeClass('hidden');
            }
        });
    };

    var stopImport = function()
    {
        importOn = false;
    };
    var startImport = function()
    {
        importOn = true;
    };


    /**
     * Changes counters in Import line
     *
     * @param importId
     * @param current
     * @param end
     */
    var updateImportInfoLine = function(importId, current, end) {

    };

    return {
        init: function() {
            submitFormViaAjax();
            continueImportButton();
            toggleFormAfterTypeSelect();
            cancelImportButton();
        }
    }
}();