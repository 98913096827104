var Login = function() {

    var handleForgetPassword = function() {
        jQuery('#forget-password').click(function() {
            jQuery('.login-form').addClass('hide');
            jQuery('.forget-form').removeClass('hide');
        });
        //
        jQuery('#back-btn').click(function() {
            jQuery('.login-form').removeClass('hide');
            jQuery('.forget-form').addClass('hide');
        });
    };

    return {
        //main function to initiate the module
        init: function() {
            handleForgetPassword();
        }

    };

}();

jQuery(document).ready(function() {
    Login.init();
});