/**
 * JS Tree functionality
 *
 * @author Antanas Kontrimas <antanas@koralis.lt>
 * @author Anton Šumilov <anton@koralis.lt>
 */

var Tree = function (options) {

    var self = this;
    self.options = $.extend({
        tree: $('.tree-generate'),
        activeNode: '#',
        activeShop: 1,
        resource: 'cms'
    }, options || {});
    // create new page button event
    var createPage = function() {
        $(document).find('.create_page').on('click', function() {
            self.options.tree.jstree(true).create_node(
                '#',
                "",
                "last"
            );
        });
    };

    // delete existing page button event
    var deletePage = function() {
        $(document).find('#page_delete').on('click', function() {
            self.options.tree.jstree(true).delete_node(getActiveNode());
        });
    };

    /* event when node is moved to another node */
    var listenTreeChanges = function(){

        // event when node is moved
        self.options.tree.on('move_node.jstree', function (e, node) {
            setActiveNode(node.node.id);

            var ids = [];
            if (node.parent == '#') {
                $(document).find('.jstree ul:first li').each(function () {
                    ids.push($(this).attr('id'));
                });
                node.parent = 0;
            } else {
                if ($(document).find('#' + node.parent + ' ul:first li').length === 0) {
                    ids.push(node.node.id);
                } else {
                    $(document).find('#' + node.parent + ' ul:first li').each(function () {
                        ids.push($(this).attr('id'));
                    });
                }
            }

            $.ajax({
                url: '/'+self.options.resource+'/'+getActiveNode(),
                type: 'put',
                data: {
                    parent: node.parent,
                    position: ids,//node.position,
                    only_position: true
                },
                success: function(response) {
                    if (!response.success) {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });

        // event when node is created
        self.options.tree.on('create_node.jstree', function (e, node) {
            var data = {
                shop_id: getActiveShop(),
                parent: node.parent,
                position: node.position
            };

            $.ajax({
                url: '/'+self.options.resource,
                type: "post",
                dataType: "json",
                data: data,
                success: function(response) {
                    if (response.success) {
                        setActiveNode(response.data.id);
                        self.options.tree.jstree(true).deselect_all();
                        self.options.tree.jstree('refresh');
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });

        // event when node is deleted
        self.options.tree.on('delete_node.jstree', function (e, node) {
            $.ajax({
                url: '/'+self.options.resource+'/'+getActiveNode(),
                type: "delete",
                success: function(response) {
                    self.options.tree.jstree(true).deselect_all();
                    setActiveNode('');
                    $('#tree-edit-form').html('');
                }
            });
        });

        // event when node is clicked
        self.options.tree.on('select_node.jstree', function (e, node) {
            setActiveNode(node.node.id);
            self.options.tree.jstree(true).select_node(getActiveNode());

            $.ajax({
                url: '/'+self.options.resource+'/'+getActiveNode()+'/edit',
                type: "get",
                success: function(response){
                    if (response.success) {
                        $('#tree-edit-form').html(response.html);
                        App.initAjax();
                        Form.init();
                        Pages.handleModules();
                    } else {
                        Notification.toastr().error('', response.error);
                    }
                }
            });
        });

        // event when tree is refreshed
        self.options.tree.one("refresh.jstree", function () {
            self.options.tree.jstree(true).select_node(getActiveNode());
        });
    };

    var setActiveNode = function (value) {
        self.options.activeNode = value;
    };
    var getActiveNode = function () {
        return self.options.activeNode;
    };

    var setActiveShop = function (value) {
        self.options.activeShop = value;
    };
    var getActiveShop = function () {
        return self.options.activeShop;
    };

    var generateTree = function ()
    {
        self.options.tree.jstree({
            "core" : {
                "themes" : {
                    "responsive": false
                },
                "check_callback" : true,
                'data' : {
                    url: "/"+self.options.resource,
                    dataType: "json",
                    data: function(node) {
                        return {
                            id: node.id,
                            active: getActiveNode()
                        }
                    }
                }
            },
            "types" : {
                "default" : {
                    "icon" : "fa fa-folder icon-state-warning icon-lg"
                },
                "file" : {
                    "icon" : "fa fa-file icon-state-warning icon-lg"
                }
            },
            "plugins" : ['contextmenu', 'dnd', 'types'],
            "contextmenu" : {
                items: function($node) {
                    var tree = $(".tree-generate").jstree(true);
                    return {
                        "create": {
                            "separator_before": false,
                            "separator_after": true,
                            "label": "Create",
                            "action": function (obj) {
                                tree.create_node($node);
                            }
                        },

                        "remove": {
                            "separator_before": false,
                            "separator_after": true,
                            "label": "Delete",
                            "action": function (obj) {
                                tree.delete_node($node);
                            }
                        }
                    }
                }
            }
        });

    };


    return {
        init: function (options) {
            self.options = $.extend(self.options, options || {});

            generateTree();
            deletePage();
            createPage();
            listenTreeChanges();
        },
        setActiveShop: function (value) {
            setActiveShop(value);
        },
        setActiveNode: function (value) {
            setActiveNode(value);
        }
    };
}();

